/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, DialogContentText, Grid } from '@material-ui/core';
import { Table, DialogDefault, DefaultFiltro } from '../../components';
import * as selectorsEquipamentos from '../../store/equipamento/reducer';
import * as actionsEquipamentos from '../../store/equipamento/actions';
import Formulario from './Formulario';

import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Equipamentos = ({ equipamentos, loadEquipamentos, deleteEquipamento, authData, exportEquipamento }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Tipo de Equipamento', 'Num. Patrimônio', 'Modelo', 'Num. Série', 'Código SISANT', 'Código ANATEL', 'Último Repasse/Colaborador', 'Categoria Repasse']);
  const classes = useStyles();
  const [openExport, setOpenExport] = useState(false);
  const [localFilter, setLocalFilter] = useState({});

  const renderRows = r => {
    return (
      <>
        <TableCell>{r.tipoEquipamentoNome}</TableCell>
        <TableCell>{r.numPatrimonio}</TableCell>
        <TableCell>{r.modelo}</TableCell>
        <TableCell>{r.numSerie}</TableCell>
        <TableCell>{r.codigoSisant}</TableCell>
        <TableCell>{r.codigoAnatel}</TableCell>
        <TableCell>{r.ultimoRepasse}</TableCell>
        <TableCell>{r.categoriaRepasse}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadEquipamentos(localFilter);
  }, [loadEquipamentos]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteEquipamento(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = (isSave) => {
    if (isSave) {
      loadEquipamentos(localFilter);
    }
    setOpen(false);
    setId(null);
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    let actions = [];

    if (isAdmin()) {
      actions.push({ 
        title: 'Exportar',
        onClick: () => setOpenExport(true)
      });
    }

    return actions;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useCodigo
            codigoLabel='Modelo'
            useNumSerieEquipamento
            useTipoEquipamento
            useNumSerie
            mdCodigo={3}
            xsCodigo={3}
            useColaborador
            mdInspetor={4}
            onFilter={filterData => {
              const filter = {
                ...filterData,
                modelo: filterData.codigo,
              }
              setLocalFilter(filter);
              loadEquipamentos(filter);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={equipamentos}
          titles={titles}
          headerTitle="Equipamentos"
          deleteMessage="A informação será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          hasRowActions={isAdmin()}
          hasAdd={isAdmin()}
          headerActions={getHeaderActions()}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
        />
        <DialogDefault
          open={openExport}
          useFullScreen={false}
          handlerCancel={() => setOpenExport(false)}
          handlerConfirm={() => {
            exportEquipamento(localFilter, () => {
              setOpenExport(false);
            });
          }}
          title="Confirmação"
          confirmMessage="Sim"
          cancelMessage="Não"
        >
          <DialogContentText> Deseja baixar todas as informações filtradas? </DialogContentText>
        </DialogDefault>
      </Grid>
    </>
  );
};

Equipamentos.propTypes = {
  deleteEquipamento: PropTypes.func.isRequired,
  loadEquipamentos: PropTypes.func.isRequired,
  equipamentos: PropTypes.array,
  exportEquipamento: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  equipamentos: selectorsEquipamentos.getEquipamentos(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsEquipamentos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Equipamentos));