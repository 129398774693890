import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import InputGroup from '../InputGroup';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
  datePiker: {
    width: '100%',
    '& label': {
      marginTop: 8
    },
    '& div': {
      height: 40,
      paddingLeft: 0,
      marginTop: 8,
      background: theme.palette.white,
      '& button': {
        marginTop: -8
      }
    }
  },
  datePikerDisabled: {
    '& div': {
      background: 'none'
    }
  },
  errorMessage: {
    color: 'red',
    margin: '8px 12px 0',
    minHeight: '1em',
    textAlign: 'left',
    fontWeight: 300,
    lineHeight: '1em',
    letterSpacing: 0.33,
    fontSize: 11,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  }
}));

const DatePickerComponent = ({ onChange, value, label, disabled, isRequired, error }) => {
  const classes = useStyles();
  return (
    <InputGroup label={label} isRequired={isRequired}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker  
          autoOk
          className={clsx(classes.datePiker, disabled && classes.datePikerDisabled )}
          format="DD/MM/YYYY"
          inputVariant="outlined"
          onChange={onChange}
          value={value && typeof value === 'function' ? null : value}
          variant="inline"
          disabled={disabled}
          animateYearScrolling
        />
      </MuiPickersUtilsProvider>
      {error && <p className={classes.errorMessage}> O campo é obrigatório </p>}
    </InputGroup>
  );
}

DatePickerComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  isRequired: PropTypes.any,
  error: PropTypes.bool
};

DatePickerComponent.defaultProps = {
  disabled: false,
  isRequired: false,
  error: false,
  value: null
};

export default DatePickerComponent;
