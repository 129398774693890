import React from 'react';
import PropTypes from 'prop-types';
import DefaultSelect from '../DefaultSelect';

const TipoEquipamentoSelect = ({
  error,
  onChange,
  value,
  disabled,
  isRequired,
  showEmpty
}) => (
  <DefaultSelect
    error={error}
    showEmpty={showEmpty}
    isRequired={isRequired}
    label={"Tipo Equipamento"}
    name={"tipoEquipamento"}
    onSelect={e => onChange(e.target.value ? e.target.value : null)}
    options={[
      { id: 'CELULAR', descricao: 'Celular' },
      { id: 'DRONE', descricao: 'Drone' },
      { id: 'IPAD', descricao: 'iPad' },
      { id: 'MONITOR', descricao: 'Monitor' },
      { id: 'NOTEBOOK', descricao: 'Notebook' },
      { id: 'RADIO', descricao: 'Rádio' },
      { id: 'TERMOVISOR', descricao: 'Termovisor' },
    ]}
    value={value}
    handlerOption={option => option.descricao}
    disabled={disabled}
  />
);

TipoEquipamentoSelect.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.any,
  showEmpty: PropTypes.bool
};

TipoEquipamentoSelect.defaultProps = {
  value: '',
  showEmpty: false,
  onChange: () => {}
};

export default TipoEquipamentoSelect;