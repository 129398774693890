/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { ArquivoContainer, Input } from '../../components';

const useStyles = makeStyles(() => ({
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const ArquivosTab = ({ values, setValues }) => {
  const classes = useStyles();

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          label="CTPS"
          name="ctps"
          onChange={handleChange}
          value={values.ctps}
        />
      </Grid>
      <Grid item xs={2} className={classes.fieldContainer}>
        <Input
          label="CTPS Serie"
          name="ctpsSerie"
          onChange={handleChange}
          value={values.ctpsSerie}
        />
      </Grid>
      <ArquivoContainer 
        arquivos={values.arquivos}
        onSave={arquivos => setValues({ ...values, arquivos })}
      />
    </Grid>
  );
};

ArquivosTab.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default ArquivosTab;
