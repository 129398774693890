import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as selectorsConfig from '../../store/config/reducer';
import DefaultSelect from '../DefaultSelect';

const EstadoInput = ({ estados, onSelect, estado, isRequired, error, helperText }) => {
  const handleChange = (event) => onSelect(event.target.value);
  return (
    <DefaultSelect 
      error={error}
      helperText={helperText}
      isRequired={isRequired}
      label="Estado"
      onSelect={handleChange}
      options={estados}
      value={estado}
    />
  );
}

EstadoInput.propTypes = {
  error: PropTypes.any,
  estado: PropTypes.string,
  estados: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};

EstadoInput.defaultProps = {
  error: null,
  helperText: null,
  isRequired: false
};

const mapStateToProps = state => ({
  estados: selectorsConfig.getEstados(state),
})

export default connect(mapStateToProps, null)(EstadoInput);