import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, CardActions, Button, Divider, CardHeader, IconButton } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { findVeiculosOptions } from '../../store/veiculo/actions';
import { getVeiculosOptions } from '../../store/veiculo/reducer';

import moment from 'moment';
import Input from '../Input';
import MalhaSelect from '../MalhaSelect';
import GerenciaSelect from '../GerenciaSelect';
import PoloSelect from '../PoloSelect';
import SubestacaoSelect from '../SubestacaoSelect';
import AlimentadorSelect from '../AlimentadorSelect';
import NsirStatusSelect from '../NsirStatusSelect';
import UsuarioSelect from '../UsuarioSelect';
import DatePicker from '../DatePicker';
import TrueFalseSelect from '../TrueFalseSelect';
import NumberInput from '../NumberInput';
import MultiSelect from '../MultiSelect';
import DateTypeSelect from '../DateTypeSelect';
import SearchSelect from '../SearchSelect';
import clsx from 'clsx';

import * as selectorsAuth from '../../store/auth/reducer';
import * as selectors from '../../store/filtro/reducer';
import * as actions from '../../store/filtro/actions';
import * as Role from '../../constants/role';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.bar.grayLight,
    overflow: 'inherit'
  },
  cardActions: {
    padding: theme.spacing(2)
  },
  actionsButtonContainer: {
    marginLeft: 'auto',
  },
  actionsButton: {
    marginRight: 10
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardHeader: {
    paddingTop: 10,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  divider: {
    backgroundColor: theme.palette.bar.grayMiddle
  },
  breakline: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  }
}));

const getDefaultDates = useDates => {
  if (!useDates) return {};
  return {
    inicio: moment().subtract(1, 'year').startOf('year').valueOf(),
    fim: moment().endOf('day').valueOf()
  }
}

const Filtro = ({ onFilter, useEquipamento, useCodigo, useInspetor, useInspetorMultiSelect, useInspetorAuxiliar, useInspetorAuxiliarMultiSelect, useDigitador, useMalha, useGerencia, usePolo, useSubestacao, useAlimentador, useNsirStatus, multipleStatus, codigoLabel, 
  useCtPlan, useCtExec, useDates, usePossuiCroqui, defaultStatus, cacheFiltroNsir, setCacheFiltroNsir, optionsStatus, userRole, useNsirPagaInspetor, useApro,
  useHd, possuiHd, useConvocacaoStatus, useDateTypes, defaultDateTypes, possuiApro, useNumNsmp, usePrioridade, findVeiculosOptions, veiculosOptions, useVeiculo }) => {

  const classes = useStyles();
 
  const [filter, setFilter] = useState({
    status: defaultStatus ? defaultStatus : [],
    dateTypes: defaultDateTypes ? defaultDateTypes : [],
    ...getDefaultDates(useDates)
  });
  const [expanded, setExpanded] = useState(false);
  const [status, setStatus] = useState([
    {'id': 'EM_CRIACAO', nome: 'Em Criação'},
    {'id': 'AGUARDANDO_INSPECAO', nome: 'Aguardando Inspeção'},
    {'id': 'INSPECAO_EM_ENVIO', nome: 'Inspeção em Envio'},
    {'id': 'EM_INSPECAO', nome: 'Em Inspeção'},
    {'id': 'INSPECAO_ENVIADA_PARCIAL', nome: 'Inspeção Enviada Parcial'},
    {'id': 'INSPECAO_SYNC_ERROR', nome: 'Inspeção com Erro de Envio'},
    {'id': 'INSPECAO_FINALIZADA', nome: 'Inspeção Finalizada'},
    {'id': 'AGUARDANDO_DIGITACAO', nome: 'Aguardando Digitação'},
    {'id': 'EM_DIGITACAO', nome: 'Em Digitação'},
    {'id': 'DIGITACAO_FINALIZADA', nome: 'Digitação Finalizada'},
    {'id': 'EXECUTADA_GDIS', nome: 'Executada GDIS'}
  ]);

  useEffect(() => {
    if (optionsStatus) setStatus(optionsStatus);
  }, [optionsStatus]);

  useEffect(() => {
    if (useVeiculo) findVeiculosOptions();
  }, [useVeiculo, findVeiculosOptions]);

  useEffect(() => {
    if (Object.keys(cacheFiltroNsir).length > 0) {
      setFilter({...getDefaultDates(useDates), ...cacheFiltroNsir});
    }
  }, [cacheFiltroNsir, useDates]);

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title="Filtros"
        onClick={() => setExpanded(!expanded)}
        action={
          <IconButton 
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            {useDates && <>
              <Grid item md={2} xs={6}>
                <DatePicker
                  label="Data Início"
                  onChange={data => setFilter({
                    ...filter,
                    inicio: data.valueOf()
                  })}
                  value={filter.inicio}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <DatePicker
                  label="Data Fim"
                  onChange={data => setFilter({
                    ...filter,
                    fim: data.valueOf()
                  })}
                  value={filter.fim}
                />
              </Grid>
              {useDateTypes && 
                <Grid item md={8} xs={12}>
                  <DateTypeSelect
                    label="Datas"
                    onSelect={dateTypes => setFilter({
                      ...filter,
                      dateTypes
                    })}
                    value={filter.dateTypes}
                  />
                </Grid>
              }
              {!useDateTypes && <Grid className={classes.breakline} item xs={12} />}
            </>}
            {useCodigo && <Grid item md={2} xs={6}>
              <Input
                label={codigoLabel ? codigoLabel : 'Código'}
                onChange={event => setFilter({
                  ...filter,
                  codigo: event.target.value
                })}
                placeholder={codigoLabel ? codigoLabel : 'Código'}
                value={filter.codigo}
              />
            </Grid>}
            {useEquipamento && <Grid item md={2} xs={6}>
              <Input
                label={'Equipamento'}
                onChange={event => setFilter({
                  ...filter,
                  equipamento: event.target.value
                })}
                placeholder={'Equipamento'}
                value={filter.equipamento}
              />
            </Grid>}
            {useInspetor && Role.INSPETOR !== userRole && <Grid item md={2} xs={6}>
              <UsuarioSelect
                label="Inspetor"
                onSelect={inspetorId => setFilter({
                  ...filter,
                  inspetorId
                })}
                ativo
                multiSelect={useInspetorMultiSelect}
                grupos={[Role.ADMIN, Role.INSPETOR]}
                value={filter.inspetorId}
              />
            </Grid>}
            {useInspetorAuxiliar && Role.INSPETOR !== userRole && <Grid item md={2} xs={6}>
              <UsuarioSelect
                label="Inspetor"
                onSelect={inspetorAuxiliarId => setFilter({
                  ...filter,
                  inspetorAuxiliarId
                })}
                ativo
                multiSelect={useInspetorAuxiliarMultiSelect}
                grupos={[Role.ADMIN, Role.INSPETOR]}
                value={filter.inspetorAuxiliarId}
              />
            </Grid>}
            {useDigitador && Role.DIGITADOR !== userRole && <Grid item md={2} xs={6}>
              <UsuarioSelect
                label="Digitador"
                onSelect={digitadorId => setFilter({
                  ...filter,
                  digitadorId
                })}
                ativo
                grupos={[Role.ADMIN, Role.DIGITADOR]}
                value={filter.digitadorId}
              />
            </Grid>}
            {useCtPlan &&<Grid item md={2} xs={6}>
              <Input
                label="CT. Plan"
                onChange={event => setFilter({
                  ...filter,
                  ctPlan: event.target.value
                })}
                placeholder="CT. Plan"
                value={filter.ctPlan}
              />
            </Grid>}
            {useCtExec &&<Grid item md={2} xs={6}>
              <Input
                label="CT. Exec"
                onChange={event => setFilter({
                  ...filter,
                  ctExec: event.target.value
                })}
                placeholder="CT. Exec"
                value={filter.ctExec}
              />
            </Grid>}
            {useMalha && <Grid item md={3} xs={6}>
              <MalhaSelect
                onSelect={value => setFilter({
                  ...filter,
                  malhaId: value,
                  gerenciaId: null,
                  poloId: null,
                  subestacaoId: null,
                  alimentadorId: null
                })}
                value={filter.malhaId}
              />
            </Grid>}
            {useGerencia && <Grid item md={2} xs={6}>
              <GerenciaSelect
                onSelect={value => setFilter({
                  ...filter,
                  gerenciaId: value,
                  poloId: null,
                  subestacaoId: null,
                  alimentadorId: null
                })}
                value={filter.gerenciaId}
                malhaId={filter.malhaId}
              />
            </Grid>}
            {usePolo && <Grid item md={3} xs={6}>
              <PoloSelect
                onSelect={value => setFilter({
                  ...filter,
                  poloId: value,
                  subestacaoId: null,
                  alimentadorId: null
                })}
                value={filter.poloId}
                malhaId={filter.malhaId}
                gerenciaId={filter.gerenciaId}
              />
            </Grid>}
            {useSubestacao && <Grid item md={2} xs={6}>
              <SubestacaoSelect
                onSelect={value => setFilter({
                  ...filter,
                  subestacaoId: value,
                  alimentadorId: null
                })}
                value={filter.subestacaoId}
                malhaId={filter.malhaId}
                gerenciaId={filter.gerenciaId}
                poloId={filter.poloId}
              />
            </Grid>}
            {useAlimentador && <Grid item md={2} xs={6}>
              <AlimentadorSelect
                onSelect={value => setFilter({
                  ...filter,
                  alimentadorId: value
                })}
                value={filter.alimentadorId}
                malhaId={filter.malhaId}
                gerenciaId={filter.gerenciaId}
                poloId={filter.poloId}
                subestacaoId={filter.subestacaoId}
              />
            </Grid>}
            {usePossuiCroqui && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Possui Croqui"
                onChange={event => setFilter({
                  ...filter,
                  possuiCroqui: event.target.value
                })}
                value={filter.possuiCroqui}
              />
            </Grid>}
            {useNsirPagaInspetor && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Nsir Paga Inspetor"
                onChange={event => setFilter({
                  ...filter,
                  nsirPagaInspetor: event.target.value
                })}
                value={filter.nsirPagaInspetor}
              />
            </Grid>}
            {possuiHd && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Possui HD"
                onChange={event => setFilter({
                  ...filter,
                  possuiHd: event.target.value || null
                })}
                value={filter.possuiHd}
              />
            </Grid>}
            {possuiApro && <Grid item md={2} xs={6}>
              <TrueFalseSelect
                label="Possui Apro"
                onChange={event => setFilter({
                  ...filter,
                  possuiApro: event.target.value || null
                })}
                value={filter.possuiApro}
              />
            </Grid>}
            {useApro && <Grid item md={2} xs={6}>
              <NumberInput
                label="APRO"
                name="apro"
                onChange={valor => setFilter({
                  ...filter,
                  apro: valor || null
                })}
                value={filter.apro}
                decimalScale={0}
                prefix=""
                thousandSeparator=""
              />
            </Grid>}
            {useHd && <Grid item md={2} xs={6}>
              <NumberInput
                label="HD"
                name="hd"
                onChange={valor => setFilter({
                  ...filter,
                  hd: valor || null
                })}
                value={filter.hd}
                decimalScale={0}
                prefix=""
                thousandSeparator=""
              />
            </Grid>}
            {useNumNsmp && <Grid item md={2} xs={6}>
              <Input
                label="Nº Nsmp"
                onChange={event => setFilter({
                  ...filter,
                  numNsmp: event.target.value
                })}
                value={filter.numNsmp}
              />
            </Grid>}
            {usePrioridade && <Grid item md={2} xs={6}>
              <Input
                label="Prioridade"
                onChange={event => setFilter({
                  ...filter,
                  prioridade: event.target.value
                })}
                value={filter.prioridade}
              />
            </Grid>}
            {useNsirStatus && <Grid item xs={12}>
              <NsirStatusSelect
                onSelect={value => setFilter({
                  ...filter,
                  status: value
                })}
                value={filter.status}
                isMultiple={multipleStatus}
                options={status}
              />
            </Grid>}
            {useConvocacaoStatus && <Grid item md={10} xs={12}>
              <MultiSelect
                label="Status"
                onChange={values => setFilter({
                  ...filter,
                  convocacaoStatus: values ? values.map(v => v.value) : []
                })}
                value={filter.convocacaoStatus}
                options={[
                  {'value': 'ACEITO', label: 'Aceito'},
                  {'value': 'AGUARDANDO', label: 'Aguardando'},
                  {'value': 'CANCELADA', label: 'Cancelada'},
                  {'value': 'EXPIRADA', label: 'Expirada'},
                  {'value': 'NEGADO', label: 'Negado'},
                ]}
              />
            </Grid>}
            {useVeiculo && <Grid item md={2} xs={6}>
              <SearchSelect
                label="Veículo"
                multiSelect
                onSelect={veiculoId => setFilter({
                  ...filter,
                  veiculoId
                })}
                options={veiculosOptions ? veiculosOptions.map(v => ({ value: v.id, label: v.placa })) : []}
                value={filter.veiculoId}
              />
            </Grid>}
          </Grid>
        </CardContent>
        <Divider className={classes.divider}/>
        <CardActions className={classes.cardActions}>
          <Button 
            color="primary" 
            size="small" 
            variant="contained"
            onClick={() => {
              if (!filter.status) filter.status = null;
              setCacheFiltroNsir(filter);
              return onFilter(filter)
            }}
          >
            Filtrar
          </Button>
          <Button 
            color="primary" 
            size="small" 
            variant="contained"
            onClick={() => {
              setFilter({
                status: defaultStatus ? defaultStatus : [],
                ...getDefaultDates(useDates),
                dateTypes: defaultDateTypes ? defaultDateTypes : []
              })
            }}
          >
            Limpar
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

Filtro.propTypes = {
  onFilter: PropTypes.func.isRequired,
  useCodigo: PropTypes.bool,
  useEquipamento: PropTypes.bool,
  useInspetor: PropTypes.bool,
  useInspetorMultiSelect: PropTypes.bool,
  useInspetorAuxiliar: PropTypes.bool,
  useInspetorAuxiliarMultiSelect: PropTypes.bool,
  useDigitador: PropTypes.bool,
  useGerencia: PropTypes.bool,
  usePolo: PropTypes.bool,
  useSubestacao: PropTypes.bool, 
  useAlimentador: PropTypes.bool,
  useMalha: PropTypes.bool,
  useNsirStatus: PropTypes.bool,
  multipleStatus: PropTypes.bool,
  codigoLabel: PropTypes.string,
  useCtPlan: PropTypes.bool,
  useCtExec: PropTypes.bool,
  useDates: PropTypes.bool,
  defaultStatus: PropTypes.array,
  cacheFiltroNsir: PropTypes.object.isRequired,
  optionsStatus: PropTypes.array,
  usePossuiCroqui: PropTypes.bool,
  userRole: PropTypes.string.isRequired,
  useNsirPagaInspetor: PropTypes.bool,
  useApro: PropTypes.bool,
  useHd: PropTypes.bool,
  useConvocacaoStatus: PropTypes.bool,
  possuiHd: PropTypes.bool,
  possuiApro: PropTypes.bool,
  useVeiculo: PropTypes.bool,
};

Filtro.defaultValue = {
  useCodigo: false,
  useEquipamento: false,
  useInspetor: false,
  useInspetorMultiSelect: false,
  useInspetorAuxiliar: false,
  useInspetorAuxiliarMultiSelect: false,
  useDigitador: false,
  useMalha: false,
  useGerencia: false,
  usePolo: false,
  useSubestacao: false,
  useAlimentador: false,
  useNsirStatus: false,
  multipleStatus: false,
  codigoLabel: 'Código',
  useCtPlan: false,
  useCtExec: false,
  useDates: false,
  defaultStatus: [],
  optionsStatus: null,
  usePossuiCroqui: false,
  useNsirPagaInspetor: false,
  useApro: false,
  useHd: false,
  useConvocacaoStatus: false,
  possuiHd: false,
  possuiApro: false,
  useNumNsmp: false,
  usePrioridade: false,
  useVeiculo: false,
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  findVeiculosOptions,
}, dispatch);

const mapStateToProps = state => ({
  cacheFiltroNsir: selectors.getCacheFiltroNsir(state),
  userRole: selectorsAuth.getRole(state),
  veiculosOptions: getVeiculosOptions(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filtro);
