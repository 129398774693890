import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import {
  Assignment as AssignmentIcon,
  Settings as SettingsIcon,
  PeopleAlt as PeopleAltIcon,
  Person as PersonIcon,
  // Dashboard as DashboardIcon
  Description as DescriptionIcon,
  AttachMoney as AttachMoneyIcon,
  Schedule as ScheduleIcon,
  PostAdd as PostAddIcon
} from '@material-ui/icons';

import * as selectorsAuth from '../../../../../../store/auth/reducer';
import * as Role from '../../../../../../constants/role';

import Profile from './Profile';
import SidebarNav from './SidebarNav';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
      borderRight: 'none'
    }
  },
  root: {
    backgroundColor: theme.palette.bar.principal,
    display: 'flex',
    height: '150%',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.bar.light
  }
}));

const Sidebar = ({ open, variant, onClose, userRole, authData }) => {
  const classes = useStyles();

  const pages = () => ([
    // {
    //   title: 'Dashboard',
    //   icon: <DashboardIcon />,
    //   href: '/dashboard',
    //   show: userRole === Role.SUPER_ADMIN || userRole === Role.ADMIN
    // },
    {
      title: 'Meu Perfil',
      icon: <PersonIcon />,
      href: '/meu-perfil',
      show: true,
    },
    {
      title: 'Inspeção',
      icon: <AssignmentIcon />,
      show: true,
      options: [
        {
          title: 'NSIRs',
          href: '/nsirs',
          isActive: (match, location) => match || (location && location.pathname.includes('/nsir')),
          show: true,
        },
        {
          title: 'Convocações',
          href: '/convocacoes',
          show: true,
        },
        // {
        //   title: 'Drones',
        //   href: '/drones',
        //   show: true,
        // },
        {
          title: 'Equipamentos',
          href: '/equipamentos',
          show: authData.email === 'vinicius_admin' || authData.email === 'MARIA' || authData.email === 'Adriana Machado de Carvalho Mendes' || authData.email === 'REGIANE MARIA DE OLIVEIRA' || authData.email === 'Breno' || authData.email === 'MAURO' || authData.email === 'Luciano Rocha'
        },
      ]
    },
    {
      title: 'Financeiro',
      icon: <AttachMoneyIcon />,
      show: true,
      options: [
        {
          title: 'Aportes',
          href: '/aportes',
          isActive: (match, location) => match || (location && location.pathname.includes('/aporte')),
          show: userRole !== Role.CROQUI && userRole !== Role.DIGITADOR
        },
        {
          title: 'Cartões',
          href: '/cartoes',
          show: userRole === Role.SUPER_ADMIN || userRole === Role.ADMIN
        },
        // {
        //   title: 'Controle de Caixa',
        //   href: '/controle-caixa',
        //   show: userRole === Role.SUPER_ADMIN || userRole === Role.ADMIN
        // },
        {
          title: 'Notas',
          href: '/notas',
          show: userRole !== Role.CROQUI && userRole !== Role.DIGITADOR
        },
        {
          title: 'Odômetros',
          href: '/odometros',
          show: true,
        },
        {
          title: 'Veículos',
          href: '/veiculos',
          show: true,
        },
        {
          title: 'Inspeções Veiculares',
          href: '/inspecoes-veiculares',
          show: true,
        },
      ]
    },
    {
      title: 'Estoque',
      icon: <PostAddIcon />,
      show: authData.email === 'vinicius_admin' || authData.email === 'MARIA' || authData.email === 'REGIANE MARIA DE OLIVEIRA',
      options: [
        {
          title: 'Estoque',
          href: '/estoques',
          show: authData.email === 'vinicius_admin' || authData.email === 'MARIA' || authData.email === 'REGIANE MARIA DE OLIVEIRA',
        },
        {
          title: 'Pedidos',
          href: '/estoque/pedidos',
          isActive: (match, location) => match || (location && location.pathname.includes('/estoque/pedidos')),
          show: authData.email === 'vinicius_admin' || authData.email === 'MARIA' || authData.email === 'REGIANE MARIA DE OLIVEIRA',
        },
        {
          title: 'Produtos',
          href: '/estoque/produtos',
          isActive: (match, location) => match || (location && location.pathname.includes('/estoque/produtos')),
          show: authData.email === 'vinicius_admin' || authData.email === 'MARIA' || authData.email === 'REGIANE MARIA DE OLIVEIRA',
        }
      ]
    },
    {
      title: 'Usuários',
      icon: <PeopleAltIcon />,
      href: '/usuarios',
      show: true
    },
    {
      title: 'Controle de Ponto',
      icon: <ScheduleIcon />,
      href: '/controle-ponto',
      // show: authData && (authData.matricula === 'M0269' || authData.matricula === 'M0177'),
      show: false
    },
    {
      title: 'Documentos',
      icon: <DescriptionIcon />,
      href: '/documentos',
      show: userRole === Role.SUPER_ADMIN || userRole === Role.ADMIN
    },
    {
      title: 'Configurações',
      icon: <SettingsIcon />,
      show: userRole === Role.SUPER_ADMIN || userRole === Role.ADMIN,
      options: [
        {
          title: 'Malhas',
          href: '/malhas',
          show: true,
        },
        {
          title: 'Gerências',
          href: '/gerencias',
          show: true,
        },
        {
          title: 'Polos',
          href: '/polos',
          show: true,
        },
        {
          title: 'Subestações',
          href: '/subestacoes',
          show: true,
        },
        {
          title: 'Alimentadores',
          href: '/alimentadores',
          show: true,
        }
      ]
    },
  ]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={classes.root}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav pages={pages()}/>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  userRole: selectorsAuth.getRole(state),
  authData: selectorsAuth.getAuth(state)
});

export default connect(mapStateToProps)(Sidebar);