/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, DialogContentText, Grid } from '@material-ui/core';
import { Table, DialogDefault, DefaultFiltro } from '../../components';
import * as selectorsVeiculos from '../../store/veiculo/reducer';
import * as actionsVeiculos from '../../store/veiculo/actions';
import Formulario from './Formulario';

import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const Veiculos = ({ veiculos, loadVeiculos, deleteVeiculo, authData, exportVeiculo }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Placa', 'Modelo', 'Proprietário', 'Km Inicial / Km Atual', 'Ativo', 'Último Repasse', 'Região']);
  const classes = useStyles();
  const [openExport, setOpenExport] = useState(false);
  const [localFilter, setLocalFilter] = useState({});

  const getTipo = (tipo) => {
    if (tipo === 'ELETROPONTES') {
      return 'Eletropontes';
    } else if (tipo === 'PARTICULAR') {
      return 'Particular';
    } else if (tipo === 'ALUGADO') {
      return 'Alugado';
    }
  }
  
  const renderRows = r => {
    return (
      <>
        <TableCell>{r.placa}<br/>({r.categoriaNome})</TableCell>
        <TableCell>{r.modelo}</TableCell>
        <TableCell>{getTipo(r.tipoVeiculo)}</TableCell>
        <TableCell>{r.kmInicial || 'N/A'} - {r.kmAtual || 'N/A'}</TableCell>
        <TableCell>{r.ativo === true ? 'Sim' : 'Não'}</TableCell>
        <TableCell>{r.ultimoRepasse}</TableCell>
        <TableCell>{r.regiaoUltimoRepasse}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadVeiculos(localFilter)
  }, [loadVeiculos]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onDelete = id => deleteVeiculo(id);
  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onClose = (isSave) => {
    if (isSave) {
      loadVeiculos(localFilter);
    }
    setOpen(false);
    setId(null);
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    let actions = [];

    if (isAdmin()) {
      actions.push({ 
        title: 'Exportar',
        onClick: () => setOpenExport(true)
      });
    }

    return actions;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useCategoriaVeiculo
            useVeiculo
            useTipoVeiculo
            useAtivo
            useGaragem
            useCodigo
            codigoLabel="Modelo"
            filterData={localFilter}
            onFilter={filterData => {
              setLocalFilter(filterData);
              loadVeiculos(filterData);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table 
          rows={veiculos}
          titles={titles}
          headerTitle="Veículos"
          deleteMessage="A informação será removido e não poderá ser mais recuperado."
          renderRows={renderRows}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={onAdd}
          showDelete={false}
          hasRowActions={isAdmin()}
          hasAdd={isAdmin()}
          headerActions={getHeaderActions()}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
        />
        <DialogDefault
          open={openExport}
          useFullScreen={false}
          handlerCancel={() => setOpenExport(false)}
          handlerConfirm={() => {
            exportVeiculo(localFilter, () => {
              setOpenExport(false);
            });
          }}
          title="Confirmação"
          confirmMessage="Sim"
          cancelMessage="Não"
        >
          <DialogContentText> Deseja baixar todas as informações filtradas? </DialogContentText>
        </DialogDefault>
      </Grid>
    </>
  );
};

Veiculos.propTypes = {
  deleteVeiculo: PropTypes.func.isRequired,
  loadVeiculos: PropTypes.func.isRequired,
  veiculos: PropTypes.array,
  exportVeiculo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  veiculos: selectorsVeiculos.getVeiculos(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsVeiculos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Veiculos));