import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../MultiSelect';

const DateTypeSelect = ({ onSelect, value }) => {
  const handler = newValue => {
    let result = null;
    if (newValue) {
      result = newValue.map(v => v.value);
    }
    onSelect(result);
  }

  const [options] = useState([
    {value: 'DATA_CRIACAO', label: 'Data Criação'},
    {value: 'DATA_DIGITACAO', label: 'Data Digitação'},
    {value: 'DATA_EXECUTADA_GDIS', label: 'Data Executada GDIS'},
    {value: 'DATA_INSPECAO', label: 'Data Inspeção'},
    {value: 'DATA_VENCIMENTO', label: 'Data Vencimento'},
  ]);

  return (
    <>
      <MultiSelect
        label="Status"
        onChange={newValue => handler(newValue)}
        defaultValue={options && value? options.filter(o => value.indexOf(o.value) > -1) : []}
        options={options}
      /> 
    </>
  );
}

DateTypeSelect.propTypes = {
  value: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};

DateTypeSelect.defaultProps = {
  value: null
};

export default DateTypeSelect;