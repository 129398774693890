/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, Chip, DialogContentText } from '@material-ui/core';
import { Table, Filtro, UIcon, UploadDialog, DialogDefault, ButtonMenu } from '../../components';
import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsFiltro from '../../store/filtro/reducer';
import * as actionsNsirs from '../../store/nsir/actions';
import * as actionsToast from '../../store/toast/actions';
import * as Role from '../../constants/role';
import * as Status from '../../constants/status';
import moment from 'moment';
import { Warning } from '@material-ui/icons';
import * as XLSX from 'xlsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  }
}));

const getArea = area => {
  if (area === 'RDR') return 'Rural';
  if (area === 'RDU') return 'Urbana';
  return '';
};

const destacarVencimento = (dataVencimento, dataConclusao) => {
  let cor = '#037503';
  const diffInWeeks = moment
    .duration(dataVencimento.diff(dataConclusao))
    .asWeeks();

  if (diffInWeeks <= 0) cor = '#ad0909';
  else if (diffInWeeks <= 1) cor = '#bd8c00';

  return {
    color: cor,
    border: `1px solid ${cor}`
  };
};

const obterTextoVencimento = (dataVencimento, dataConclusao) => {
  const diffInWeeks = moment
    .duration(dataVencimento.diff(dataConclusao))
    .asWeeks();
  const prefixDiffText = diffInWeeks < 0 ? 'Vencido' : 'Vencerá';
  return `${prefixDiffText} ${dataVencimento
    .locale('pt-br')
    .from(dataConclusao)}`;
};

const Nsirs = ({ importNsirs, history, cleanSelectedNsir, userRole, messageError, 
  deleteNsir, exportNsirs, cacheFiltroNsir, importNsirServices, exportToEditNsirs, exportFinanceiro,
  importNsirFinanceiro, nsirExport, defaultNsirImport, generateNsirReport, generateInspecaoReport }) => {

  const classes = useStyles();
  const [openImport, setOpenImport] = useState(false);
  const [openImportNsmps, setOpenImportNsmps] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openExportEdit, setOpenExportEdit] = useState(false);
  const [openExportServico, setOpenExportServico] = useState(false);
  const [openExportFinanceiro, setOpenExportFinanceiro] = useState(false);
  const [localFilter, setLocalFilter] = useState({});
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [openImportFinanceiro, setOpenImportFinanceiro] = useState(false);
  const [openExportDigitador, setOpenExportDigitador] = useState(false);
  const [openImportDigitador, setOpenImportDigitador] = useState(false);
  const [openExportInspecao, setOpenExportInspecao] = useState(false);

  useEffect(() => {
    if (Object.keys(cacheFiltroNsir).length > 0) {
      setLocalFilter(cacheFiltroNsir);
    } else {
      setLocalFilter({
        inicio: moment().subtract(1, 'year').startOf('year').valueOf(),
        fim: moment().endOf('day').valueOf(),
        status: [
          Status.AGUARDANDO_INSPECAO,
          Status.EM_INSPECAO, 
          Status.INSPECAO_FINALIZADA, 
          Status.INSPECAO_ENVIADA_PARCIAL,
          Status.AGUARDANDO_DIGITACAO, 
          Status.EM_DIGITACAO,
          Status.DIGITACAO_FINALIZADA
        ],
        dateTypes: [
          'DATA_CRIACAO',
          'DATA_DIGITACAO',
          'DATA_EXECUTADA_GDIS',
          'DATA_INSPECAO',
          'DATA_VENCIMENTO',
        ]
      });
    }
  }, [cacheFiltroNsir]);

  useEffect(() => {
    if (Role.INSPETOR === userRole) {
      setOptionsStatus([
        {'id': 'AGUARDANDO_INSPECAO', nome: 'Aguardando Inspeção'},
        {'id': 'INSPECAO_EM_ENVIO', nome: 'Inspeção em Envio'},
        {'id': 'EM_INSPECAO', nome: 'Em Inspeção'},
        {'id': 'INSPECAO_ENVIADA_PARCIAL', nome: 'Inspeção Enviada Parcial'},
        {'id': 'INSPECAO_SYNC_ERROR', nome: 'Inspeção com Erro de Envio'},
        {'id': 'ERRO_AO_ENVIAR_INSPECAO', nome: 'Erro ao enviar Inspeção'},
        {'id': 'INSPECAO_FINALIZADA', nome: 'Inspeção Finalizada'},
        {'id': 'AGUARDANDO_DIGITACAO', nome: 'Aguardando Digitação'},
        {'id': 'EM_DIGITACAO', nome: 'Em Digitação'},
        {'id': 'DIGITACAO_FINALIZADA', nome: 'Digitação Finalizada'},
        {'id': 'EXECUTADA_GDIS', nome: 'Executada GDIS'}
      ]);
    } else if (Role.DIGITADOR === userRole) {
      setOptionsStatus([
        {'id': 'INSPECAO_FINALIZADA', nome: 'Inspeção Finalizada'},
        {'id': 'AGUARDANDO_DIGITACAO', nome: 'Aguardando Digitação'},
        {'id': 'EM_DIGITACAO', nome: 'Em Digitação'},
        {'id': 'DIGITACAO_FINALIZADA', nome: 'Digitação Finalizada'},
        {'id': 'EXECUTADA_GDIS', nome: 'Executada GDIS'}
      ]);
    } else {
      setOptionsStatus([
        {'id': 'EM_CRIACAO', nome: 'Em Criação'},
        {'id': 'AGUARDANDO_INSPECAO', nome: 'Aguardando Inspeção'},
        {'id': 'INSPECAO_EM_ENVIO', nome: 'Inspeção em Envio'},
        {'id': 'EM_INSPECAO', nome: 'Em Inspeção'},
        {'id': 'INSPECAO_ENVIADA_PARCIAL', nome: 'Inspeção Enviada Parcial'},
        {'id': 'INSPECAO_SYNC_ERROR', nome: 'Inspeção com Erro de Envio'},
        {'id': 'ERRO_AO_ENVIAR_INSPECAO', nome: 'Erro ao enviar Inspeção'},
        {'id': 'INSPECAO_FINALIZADA', nome: 'Inspeção Finalizada'},
        {'id': 'AGUARDANDO_DIGITACAO', nome: 'Aguardando Digitação'},
        {'id': 'EM_DIGITACAO', nome: 'Em Digitação'},
        {'id': 'DIGITACAO_FINALIZADA', nome: 'Digitação Finalizada'},
        {'id': 'EXECUTADA_GDIS', nome: 'Executada GDIS'}
      ]);
    }
  }, [userRole]);

  const renderRows = d => {
    const dataVencimento = moment(d.vencimento).endOf('day');
    return (
      <>
        <TableCell size="small">
          <div style={{ display: 'flex' }}>
            {d.prioridade === 'SIM' && <Warning className={classes.icon} />}
            {d.nsmpUrgencia && <UIcon />}
          </div>
        </TableCell>
        <TableCell size="small">{d.codigo}</TableCell>
        <TableCell size="small">{d.equipamento}</TableCell>
        <TableCell size="small">{getArea(d.area)}</TableCell>
        <TableCell size="small">{d.ctPlan}</TableCell>
        <TableCell size="small">{d.ctExec}</TableCell>
        <TableCell size="small">
          {d.malhaCodigo && d.alimentadorCodigo
            ? `${d.malhaCodigo} - ${d.alimentadorCodigo}`
            : d.malhaCodigo
              ? d.malhaCodigo
              : d.alimentadorCodigo
                ? d.alimentadorCodigo
                : ''}
        </TableCell>
        <TableCell size="small">{d.inspetor}</TableCell>
        <TableCell size="small">{d.digitador}</TableCell>
        <TableCell size="small">{d.statusName}</TableCell>
        <TableCell size="small">
          <span style={{ marginRight: '5px' }}>
            {dataVencimento.format('DD/MM/YYYY')}
          </span>
          {(!d.dataConclusao && d.status !== Status.EXECUTADA_GDIS) && (
            <>
              <br/> 
              <Chip
                variant="outlined"
                style={destacarVencimento(dataVencimento, moment())}
                size="small"
                label={obterTextoVencimento(dataVencimento, moment())}
              />
            </>
          )}
        </TableCell>
        <TableCell size="small">
          {d.dataConclusaoValue ? d.dataConclusaoValue : ''}
        </TableCell>
      </>
    );
  };

  const onEdit = id => {
    cleanSelectedNsir();
    history.push(`/nsir/${id}`);
  };

  const onAdd = () => {
    cleanSelectedNsir();
    history.push('/nsir');
  };

  const onDelete = (id, callback) => deleteNsir(id, callback);

  const getHeaderActions = () => {
    return [{ 
      isComponent: true,
      component: <ButtonMenu title="Importar" options={[
        {
          title: "NSIRs",
          onClick: () => setOpenImport(true),
          show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
        },
        {
          title: "Serviços",
          onClick: () => setOpenImportNsmps(true),
          show: Role.CROQUI !== userRole && Role.DIGITADOR !== userRole
        },
        {
          title: "Financeiro",
          onClick: () => setOpenImportFinanceiro(true),
          show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
        },
        {
          title: "Digitadores",
          onClick: () => setOpenImportDigitador(true),
          show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
        }
      ]}/>
    }, 
    { 
      isComponent: true,
      component: <ButtonMenu title="Exportar" options={[
          {
            title: "NSIRs",
            onClick: () => setOpenExport(true),
            show: true
          },
          {
            title: "P/ Edição",
            onClick: () => setOpenExportEdit(true),
            show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
          },
          {
            title: "Financeiro",
            onClick: () => setOpenExportFinanceiro(true),
            show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
          },
          {
            title: "Digitadores",
            onClick: () => setOpenExportDigitador(true),
            show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
          },
          {
            title: "Serviços",
            onClick: () => setOpenExportServico(true),
            show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
          },
          {
            title: "Inspeção",
            onClick: () => setOpenExportInspecao(true),
            show: Role.ADMIN === userRole || Role.SUPER_ADMIN === userRole
          },
        ]}
      />
    }]
  }

  const onImport = files => {
    const reader = new FileReader();

    reader.onload = e => {
      const workbook = XLSX.read(e.target.result, {
        type: 'binary'
      });

      if (workbook.SheetNames.includes('Nsirs')) {
        let nsirs = [];
        let excelData = XLSX.utils.sheet_to_json(workbook.Sheets['Nsirs'], {header:1}).slice(1);
        for (let index in excelData) {
          const row = excelData[index];
          if (row.length === 0) {
            continue;
          }
          nsirs.push(row);
        }
        importNsirs({ nsirs }, () => setOpenImport(false));
      } else {
        messageError({ message: "Planilha inválida. Por favor, utilize a planilha 'Planilha Importação' que se encontra na aba de Documentos!" });
      }
    };

    reader.onerror = function(ex) {
      messageError({ message: 'Erro ao abrir a planilha. Por favor, tente novamente!' });
    };
  
    reader.readAsBinaryString(files[0]);
  }

  const onImportFinanceiro = files => {
    const reader = new FileReader();

    reader.onload = e => {
      const workbook = XLSX.read(e.target.result, {
        type: 'binary'
      });

      if (workbook.SheetNames.includes('NSIRs Financeiro')) {
        let nsirs = [];
        let excelData = XLSX.utils.sheet_to_json(workbook.Sheets['NSIRs Financeiro'], {header:1}).slice(1);
        for (let index in excelData) {
          const row = excelData[index];
          if (row.length === 0) continue;
          nsirs.push(row);
        }
        importNsirFinanceiro({ nsirs }, () => setOpenImportFinanceiro(false));
      } else {
        messageError({ message: "Planilha inválida. Por favor, utilize a planilha 'Planilha Importação' que se encontra na aba de Documentos!" });
      }
    };

    reader.onerror = function(ex) {
      messageError({ message: 'Erro ao abrir a planilha. Por favor, tente novamente!' });
    };
  
    reader.readAsBinaryString(files[0]);
  }

  const onImportNsmps = files => {
    const reader = new FileReader();

    reader.onload = e => {

      const nsirData = JSON.parse(e.target.result);
      if (nsirData.nsir && nsirData.nsmps && nsirData.fotos) {
        importNsirServices(nsirData, () => setOpenImportNsmps(false));
      } else {
        messageError({ message: "Arquivo inválido. Por favor, utilize um arquivo válido para importar os serviços!" });
      }
    };

    reader.onerror = function(ex) {
      messageError({ message: 'Erro ao abrir a planilha. Por favor, tente novamente!' });
    };
  
    reader.readAsText(files[0]);
  }

  const onImportDigitadores = files => {
    const reader = new FileReader();

    reader.onload = e => {
      const workbook = XLSX.read(e.target.result, {
        type: 'binary'
      });

      if (workbook.SheetNames.includes('NSIRs Digitadores')) {
        let nsirs = [];
        let excelData = XLSX.utils.sheet_to_json(workbook.Sheets['NSIRs Digitadores'], {header:1}).slice(1);
        for (let index in excelData) {
          const row = excelData[index];
          if (row.length === 0) continue;
          nsirs.push(row);
        }
        defaultNsirImport("import/digitadores", { nsirs }, () => setOpenImportDigitador(false));
      } else {
        messageError({ message: "Planilha inválida. Por favor, exporte a planilha de digitadores e utilize-a!" });
      }
    };

    reader.onerror = () => {
      messageError({ message: 'Erro ao abrir a planilha. Por favor, tente novamente!' });
    };
  
    reader.readAsBinaryString(files[0]);
  }

  const onExport = () => {
    if (openExportEdit) {
      exportToEditNsirs(localFilter, () => setOpenExportEdit(false));
    } else if (openExport) {
      exportNsirs(localFilter, () => setOpenExport(false));
    } else if (openExportFinanceiro) {
      exportFinanceiro(localFilter, () => setOpenExportFinanceiro(false));
    } else if (openExportDigitador) {
      nsirExport("export/digitadores", "RelatórioDigitadores.xlsx", localFilter, () => setOpenExportDigitador(false));
    } else if (openExportServico) {
      generateNsirReport(localFilter, () => setOpenExportServico(false));
    } else if (openExportInspecao) {
      generateInspecaoReport(localFilter, () => setOpenExportInspecao(false));
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Filtro
            onFilter={filterData => {
              setLocalFilter(filterData);
            }}
            codigoLabel="Nsir"
            useDates
            useCodigo
            useEquipamento
            useInspetor
            useDigitador
            useMalha
            useGerencia
            usePolo
            useSubestacao
            useAlimentador
            useNsirStatus
            multipleStatus
            useCtPlan
            useCtExec
            usePossuiCroqui
            useNsirPagaInspetor
            useApro
            useHd
            possuiHd
            possuiApro
            useNumNsmp
            usePrioridade
            defaultStatus={[
              Status.AGUARDANDO_INSPECAO,
              Status.EM_INSPECAO, 
              Status.INSPECAO_FINALIZADA, 
              Status.INSPECAO_ENVIADA_PARCIAL,
              Status.AGUARDANDO_DIGITACAO, 
              Status.EM_DIGITACAO,
              Status.DIGITACAO_FINALIZADA
            ]}
            optionsStatus={optionsStatus}
            useDateTypes
            defaultDateTypes={[
              'DATA_CRIACAO',
              'DATA_DIGITACAO',
              'DATA_EXECUTADA_GDIS',
              'DATA_INSPECAO',
              'DATA_VENCIMENTO',
            ]}
          />
        </Grid>
        <Grid item xs={12} className={classes.root}>
          <Table
            titles={[
              '',
              'NSIR',
              'Equipamento',
              'Área',
              'CT. Plan',
              'CT. Exec',
              'Malha/Regional - Alimentador',
              'Inspetor',
              'Digitador',
              'Status',
              'Data Vencimento',
              'Data Finalizada GDIS'
            ]}
            headerTitle="NSIRs"
            renderRows={renderRows}
            hasRowActions
            onEdit={onEdit}
            onAdd={onAdd}
            showDelete={Role.ADMIN === userRole}
            onDelete={onDelete}
            hasAdd={Role.ADMIN === userRole}
            headerActions={getHeaderActions()}
            useFilterData
            usePaginationApi
            paginationApi="nsirs/filter"
            paginationUrl="nsirs"
            filterData={localFilter}
          />
        </Grid>
      </Grid>
      <UploadDialog
        onClose={() => setOpenImport(false)}
        onSave={onImport}
        open={openImport}
        acceptedFiles={['.xls','.xlsx']}
      />
      <UploadDialog
        onClose={() => setOpenImportNsmps(false)}
        onSave={onImportNsmps}
        open={openImportNsmps}
        acceptedFiles={['.json']}
        dialogTitle="Importar Serviços"
      />
      <UploadDialog
        onClose={() => setOpenImportFinanceiro(false)}
        onSave={onImportFinanceiro}
        open={openImportFinanceiro}
        acceptedFiles={['.xls','.xlsx']}
        dialogTitle="Importar Financeiro"
      />
      <UploadDialog
        onClose={() => setOpenImportDigitador(false)}
        onSave={onImportDigitadores}
        open={openImportDigitador}
        acceptedFiles={['.xls','.xlsx']}
        dialogTitle="Importar Digitadores"
      />
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={onExport}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar todas as NSIRs filtradas? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openExportEdit}
        handlerCancel={() => setOpenExportEdit(false)}
        handlerConfirm={onExport}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar todas as NSIRs filtradas para edição? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openExportServico}
        handlerCancel={() => setOpenExportServico(false)}
        handlerConfirm={onExport}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar todos os serviços para as NSIRs filtradas? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openExportInspecao}
        handlerCancel={() => setOpenExportInspecao(false)}
        handlerConfirm={onExport}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar as informações da inspeção para as NSIRs filtradas? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openExportFinanceiro}
        handlerCancel={() => setOpenExportFinanceiro(false)}
        handlerConfirm={onExport}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar todas as NSIRs filtradas para financeiro? </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openExportDigitador}
        handlerCancel={() => setOpenExportDigitador(false)}
        handlerConfirm={onExport}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar todas as NSIRs filtradas para digitadores? </DialogContentText>
      </DialogDefault>
    </>
  );
};

Nsirs.propTypes = {
  importNsirs: PropTypes.func.isRequired,
  nsirs: PropTypes.array.isRequired,
  cleanSelectedNsir: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  messageError: PropTypes.func.isRequired,
  deleteNsir: PropTypes.func.isRequired,
  exportNsirs: PropTypes.func.isRequired,
  cacheFiltroNsir: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userRole: selectorsAuth.getRole(state),
  cacheFiltroNsir: selectorsFiltro.getCacheFiltroNsir(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionsNsirs,
      ...actionsToast
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Nsirs));
