/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { Input, EstadoInput, CidadeInput } from '../../components';

const useStyles = makeStyles(() => ({
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const EnderecoTab = ({ values, setValues }) => {
  const classes = useStyles();

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlerEstado = value => {
    setValues({
      ...values,
      estado: value,
      cidade: '',
    });
  }
  const handlerCidade = value => {
    setValues({
      ...values,
      cidade: value
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} className={classes.fieldContainer}>
        <Input
          label="Rua"
          name="rua"
          onChange={handleChange}
          value={values.rua}
        />
      </Grid>
      <Grid item xs={2} className={classes.fieldContainer}>
        <Input
          label="Número"
          name="numero"
          onChange={handleChange}
          value={values.numero}
        />
      </Grid>
      <Grid item xs={4} className={classes.fieldContainer}>
        <Input
          label="Complemento"
          name="complemento"
          onChange={handleChange}
          value={values.complemento}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="CEP"
          name="cep"
          onChange={handleChange}
          value={values.cep}
        />
      </Grid>
      <Grid item md={4} xs={12} className={classes.fieldContainer}>
        <EstadoInput
          estado={values.estado}
          onSelect={handlerEstado}
        />
      </Grid>
      <Grid item md={5} xs={12} className={classes.fieldContainer}>
        <CidadeInput
          cidade={values.cidade}
          estado={values.estado}
          onSelect={handlerCidade}
        />
      </Grid>
      <Grid item xs={3} className={classes.fieldContainer}>
        <Input
          label="Bairro"
          name="bairro"
          onChange={handleChange}
          value={values.bairro}
        />
      </Grid>
    </Grid>
  );
};

EnderecoTab.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default EnderecoTab;
