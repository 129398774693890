/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { useLocation, useHistory  } from "react-router-dom";
import {
  CardActions,
  DialogContentText,
  FormHelperText,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import Widget from '../Widget';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { DialogDefault } from '../index';
import TableService from '../../services/TableService';
import * as actionsLoader from '../../store/loader/actions';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end',
    backgroundColor: '#fff'
  },
  tableHeader: {
    backgroundColor: '#fff'
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.bar.grayMiddle
    },
  },
  cardHeader: {
    background: '#BEBEBE'
  },
  icon: {
    padding: 3
  },
  error: {
    color: '#e53935',
    margin: '8px 12px',
    fontSize: 12,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: 0.33
  },
}));

const getTitle = (titles, hasRowActions) => {
  const titleCells = titles.map((t, i) => (<TableCell key={i}>{t}</TableCell>));
  if (hasRowActions) {
    titleCells.push((<TableCell key={titleCells.length}>Ações</TableCell>))
  }

  return titleCells;
}

const TableComponent = ({ rows, titles, headerTitle, deleteMessage, renderRows, hasRowActions, onEdit, onDelete, onAdd, showHeader, 
  defaultRowsPerPage, actions, hasAdd, usePaginationApi, paginationUrl, paginationApi, showLoader, hideLoader, filterData, showDelete, showEdit,
  showView, onView, headerActions, useFilterData, showEditRow, showViewRow, reload, size, classWidgetCard, showError }) => {
  
  const query = new URLSearchParams(useLocation().search);

  const [deleteId, setDeleteId] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowStart, setRowStart] = useState(0);
  const [rowEnd, setRowEnd] = useState(defaultRowsPerPage);
  const [rowsPerPage, setRowsPerPage] = useState(query.get("s") ? Number(query.get("s")) : defaultRowsPerPage);
  const [page, setPage] = useState(query.get("p") ? Number(query.get("p")) : 0);
  const [localRows, setLocalRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (usePaginationApi && query && !query.get("p")) {
      history.push(`${paginationUrl}?p=${page}&s=${rowsPerPage}`);
    }
  }, [query, usePaginationApi]);

  useEffect(() => {
    if (usePaginationApi && page != null) {
      getRows(page, rowsPerPage);
    }
  }, [page, rowsPerPage, usePaginationApi]);

  useEffect(() => {
    getRows(page, rowsPerPage);
  }, [filterData, reload]);

  useEffect(() => {
    if (!usePaginationApi && rows) {
      setLocalRows(rows);
      setTotalRows(rows.length);
    }
  }, [rows, usePaginationApi]);

  const getRows = async (page, pageSize) => {
    if (paginationApi && (!useFilterData || (useFilterData && filterData != null && (Object.keys(filterData).length > 0)))) {
      showLoader();
      try {
        const response = await TableService.request(`${paginationApi}?p=${page}&s=${pageSize}`, filterData);
        setLocalRows(response && response.data ? response.data.rows : []);
        setTotalRows(response && response.data ? response.data.totalRows : 0);
      } catch (e) {
        console.error(e);
      }
      hideLoader();
    }
  }

  const handlePageChange = (_, newPage) => {
    if (usePaginationApi) {
      history.push(`${paginationUrl}?p=${Number(newPage)}&s=${rowsPerPage}`);
    } else {
      setRowEnd((Number(newPage) + 1) * rowsPerPage);
      setRowStart(Number(newPage) * rowsPerPage);
    } 
    setPage(Number(newPage));
  }

  const handleRowsPerPageChange = event => {
    if (usePaginationApi) {
      history.push(`${paginationUrl}?p=${0}&s=${event.target.value}`);
    }

    setRowStart(0);
    setPage(0);
    setRowEnd(event.target.value);
    setRowsPerPage(event.target.value);
  }

  const getId = e => e.currentTarget.closest('tr').getAttribute('id');
  const handlerEdit = e => onEdit(getId(e));
  const handlerView = e => onView(getId(e));
  const handlerDelete = e => {
    setDeleteId(getId(e));
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  }

  const callback = () => getRows(page, rowsPerPage);

  const handlerConfirm = () => {
    onDelete(deleteId, usePaginationApi ? callback : () => {});
    setOpen(false);
    setDeleteId(null);
  }

  const getActions = row => {
    return actions.map(a => 
      (!a.show || a.show(row)) && <IconButton key={a.key} onClick={(e) => a.onClick(getId(e))} className={classes.icon}> { a.icon } </IconButton>
    )
  }

  const getHeaderActions = () => {
    let localActions = [];
    if (hasAdd) localActions.push({ title: 'Novo', onClick: onAdd });
    if (headerActions) {
      localActions = [...localActions, ...headerActions];
    }

    return localActions;
  }

  const getLocalRows = () => {
    let rowsData = localRows;
    if (!usePaginationApi) {
      rowsData = localRows.slice(rowStart, rowEnd);
    }

    return rowsData.map(r => (
      <TableRow hover key={r.id} id={r.id} className={classes.tableRow}>
        {renderRows(r)}
        {hasRowActions ? 
          (<TableCell size="small">
            <Grid container spacing={2}>
              {showEdit && (!showEditRow  || showEditRow(r)) && <IconButton key="button-edit" onClick={handlerEdit} className={classes.icon}> <EditIcon/> </IconButton>}
              {showView && (!showViewRow  || showViewRow(r)) && <IconButton key="button-view" onClick={handlerView} className={classes.icon}> <VisibilityIcon/> </IconButton>}
              {actions && getActions(r)}
              {showDelete && <IconButton key="button-delete" onClick={handlerDelete} className={classes.icon}> <DeleteForeverIcon/> </IconButton> }
            </Grid>
          </TableCell>) : ''
        }
      </TableRow>
    ));
  }


  return (
    <>
      <Widget 
        title={headerTitle} 
        showHeader={showHeader}
        actions={getHeaderActions()}
        classNameCard={classWidgetCard}
      >
        <PerfectScrollbar>
          <Table size={size}>
            <TableHead className={classes.tableHeader}>
              <TableRow> {getTitle(titles, hasRowActions)} </TableRow>
            </TableHead>
            <TableBody> {getLocalRows()} </TableBody>
          </Table>
        </PerfectScrollbar>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 25]}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />
        </CardActions>
        {showError && <p class="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense Mui-error Mui-required" className={classes.error}>*** É necessario adicionar ao menos um item.</p>}
      </Widget>
      <DialogDefault
        open={open}
        handlerCancel={handleClose}
        handlerConfirm={handlerConfirm}
        title="Você tem certeza?"
        confirmMessage="Deletar"
      >
        <DialogContentText> {deleteMessage} </DialogContentText>
      </DialogDefault>
    </>
  );
};

TableComponent.propTypes = {
  deleteMessage: PropTypes.string,
  hasRowActions: PropTypes.bool,
  headerTitle: PropTypes.string,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  renderRows: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  titles: PropTypes.array.isRequired,
  showHeader: PropTypes.bool,
  defaultRowsPerPage: PropTypes.number,
  actions: PropTypes.array,
  hasAdd: PropTypes.bool,
  usePaginationApi: PropTypes.bool,
  paginationApi: PropTypes.string,
  filterData: PropTypes.object,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  onView: PropTypes.func,
  showView: PropTypes.bool,
  headerActions: PropTypes.array,
  useFilterData: PropTypes.bool,
  paginationUrl: PropTypes.string,
  showEditRow: PropTypes.func,
  showViewRow: PropTypes.func,
  size: PropTypes.string
};

TableComponent.defaultProps = {
  deleteMessage: 'A informação será removida e não poderá ser mais recuperado.',
  hasRowActions: false,
  headerTitle: '',
  onAdd: () => {},
  onDelete: () => {},
  onEdit: () => {},
  showHeader: true,
  defaultRowsPerPage: 10,
  hasAdd: true,
  actions: [],
  usePaginationApi: false,
  paginationApi: '',
  filterData: null,
  showDelete: true,
  showEdit: true,
  onView: () => {},
  showView: false,
  headerActions: null,
  useFilterData: false,
  paginationUrl: "",
  showEditRow: null,
  showViewRow: null,
  size: "medium"
};

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsLoader,
}, dispatch);

export default connect(null, mapDispatchToProps)(TableComponent);