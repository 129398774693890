/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { TableCell, DialogContentText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Table, UIcon, DropzoneModal, DialogDefault } from '../../components';
import NsmpModal from './NsmpModal';
import * as selectorsAuth from '../../store/auth/reducer';
import * as actionsNsmp from '../../store/nsmp/actions';
import * as Status from '../../constants/status';
import * as Role from '../../constants/role';

const ServicesTab = ({
  nsmps,
  nsirId,
  updateNsmp,
  saveNsmp,
  cleanSelectedNsmp,
  nsirStatus,
  userRole,
  deleteNsmp,
  authData,
  userId,
  inspetorId,
  digitadorId,
  nsirTitle,
  cloneNsmp,
  deleteCloneNsmp
}) => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openFotoInicial, setOpenFotoInicial] = useState(false);
  const [openFotoFinal, setOpenFotoFinal] = useState(false);
  const [openAnaliseRisco, setOpenAnaliseRisco] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [openDeleteClone, setOpenDeleteClone] = useState(false);
  const [cloneId, setCloneId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  const isAdmin = () => authData && (authData.role === Role.ADMIN || authData.role === Role.SUPER_ADMIN);

  useEffect(() => {
    if (nsmps) setRows(nsmps);
  }, [nsmps]);

  const onEdit = id => {
    setReadOnly(userRole === Role.DIGITADOR);
    cleanSelectedNsmp();
    setSelectedId(id);
    setOpen(true);
  };

  const onView = id => {
    setReadOnly(true);
    cleanSelectedNsmp();
    setSelectedId(id);
    setOpen(true);
  };

  const onAdd = () => {
    setReadOnly(false);
    cleanSelectedNsmp();
    setSelectedId(null);
    setOpen(true);
  };

  const handlerCancel = () => {
    setReadOnly(false);
    setOpen(false);
    setSelectedId(null);
  };

  const handlerConfirm = data => {
    setReadOnly(false);
    setOpen(false);
    setSelectedId(null);
    if (data && data.id) {
      updateNsmp({ ...data, nsirId });
    } else if (data) {
      saveNsmp({ ...data, nsirId });
    }
  };

  const isFotoInicio = nsmp => nsmp.fotoInicio != null && nsmp.fotoInicio;
  const isFotoFim = nsmp => nsmp.fotoFim != null && nsmp.fotoFim;
  const isAnaliseRisco = nsmp => nsmp.analiseRisco != null && nsmp.analiseRisco;

  const getHeaderActions = () => {
    let actions = [];
    if (nsirStatus === Status.EM_INSPECAO && rows && rows.filter(row => isFotoInicio(row)).length === 0) {
      actions.push({ 
        title: 'Foto Inicial', 
        onClick: () => setOpenFotoInicial(true)
      });
    } 

    if (nsirStatus === Status.EM_INSPECAO && rows && rows.filter(row => isFotoFim(row)).length === 0) {
      actions.push({ 
        title: 'Foto Final', 
        onClick: () => setOpenFotoFinal(true)
      });
    }

    if (nsirStatus === Status.EM_INSPECAO && rows && rows.filter(row => isAnaliseRisco(row)).length === 0) {
      actions.push({ 
        title: 'Análise de Risco', 
        onClick: () => setOpenAnaliseRisco(true)
      });
    }

    return actions;
  }

  const getTipoServico = (tipoServico, motivo, numLinhasPlanilha) => {
    const tipos = [
      {id: 'E', nome: 'E-mail'},
      {id: 'EM', nome: 'Grupo de Emergência'},
      {id: 'L', nome: 'Limpeza de Faixa'},
      {id: 'M', nome: 'Manutenção'},
      {id: 'NC', nome: 'Não Cadastrada'},
      {id: 'OB', nome: 'Objeto na Rede'},
      {id: 'OS', nome: 'OSAF'},
      {id: 'PL', nome: 'Planilha'},
      {id: 'P', nome: 'Poda RDR ou RDU'},
      {id: 'PR', nome: 'Programada'}
    ];

    const motivos = [
      {id: 'NCA', nome: 'Anotação Indevida'},
      {id: 'NCD', nome: 'Duplicidade'},
      {id: 'NCP', nome: 'Prioridade'},
      {id: 'NCPL', nome: 'Planilha'}
    ];

    const servico = tipos.filter(t => t.id === tipoServico);
    if (servico && servico.length > 0) {
      const tipoServicoNome = servico[0].nome;
      if (tipoServico === 'NC') {
        const motivoSelected = motivos.filter(t => t.id === motivo);
        if (motivoSelected && motivoSelected.length > 0) {
          return `${tipoServicoNome}/${motivoSelected[0].nome}`;
        }
      } else if (tipoServico === 'PL' && numLinhasPlanilha) {
        return `${tipoServicoNome} - ${numLinhasPlanilha} linha(s)`;
      }

      return tipoServicoNome;
    }

    return "";
  }

  const renderRows = row => {
    return (
      <>
        <TableCell style={{ width: 20 }}>
          {row.prioridade && row.prioridade.toLowerCase() === 'u' ? <UIcon /> : ''}
        </TableCell>
        <TableCell>{isFotoInicio(row)? 'Foto Inicial' : isFotoFim(row)? 'Foto Final' : isAnaliseRisco(row) ? 'Análise de Risco' : row.codigo} </TableCell>
        <TableCell>{row.prioridade}</TableCell>
        <TableCell>{row.servico}</TableCell>
        <TableCell>{getTipoServico(row.tipoServico, row.motivo, row.numLinhasPlanilha)}</TableCell>
        <TableCell>{row.nsmp}</TableCell>
      </>
    );
  };

  const showEditRow = row => !isFotoInicio(row) && !isFotoFim(row) && !isAnaliseRisco(row);

  const onDelete = id => {
    deleteNsmp(id);
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const getFotos = async fotos => {
    const fotosList = [];

    for(let i in fotos) {
      fotosList.push({
        id: uuidv4(),
        nome: fotos[i].name,
        image: await toBase64(fotos[i])
      })
    }

    return fotosList;
  } 

  return (
    <>
      <Table
        rows={rows}
        titles={['', 'N° serviço', 'Prioridade', 'Serviço', 'Tipo Serviço', 'Nsmp']}
        headerTitle={`Serviços > ${nsirTitle}`}
        showHeader={false}
        renderRows={renderRows}
        showEditRow={showEditRow}
        hasRowActions
        onEdit={onEdit}
        onAdd={onAdd}
        onView={onView}
        onDelete={onDelete}
        showDelete={
          nsirStatus === Status.EM_INSPECAO && inspetorId === userId
        }
        showEdit={
          (nsirStatus === Status.EM_INSPECAO && inspetorId === userId) ||
          (nsirStatus === Status.EM_DIGITACAO && digitadorId === userId)
        }
        showView={isAdmin() ||
          (nsirStatus !== Status.EM_INSPECAO && nsirStatus !== Status.EM_DIGITACAO && 
            !(nsirStatus === Status.INSPECAO_FINALIZADA && authData.role === Role.DIGITADOR))
        }
        hasAdd={nsirStatus === Status.EM_INSPECAO}
        headerActions={getHeaderActions()}
        actions={
          [
            {
              key: 'clone',
              icon: <AddIcon />,
              show: (row) => !row.fotoInicio && !row.fotoFim && !row.analiseRisco && !row.idNsmpReference && nsirStatus === Status.EM_DIGITACAO,
              onClick: id => {
                setOpenClone(true);
                setCloneId(id);
              }
            },
            {
              key: 'delete',
              icon: <DeleteIcon />,
              show: (row) => row.idNsmpReference && nsirStatus === Status.EM_DIGITACAO,
              onClick: id => {
                setOpenDeleteClone(true);
                setCloneId(id);
              }
            },
          ]
        }
      />
      <NsmpModal
        open={open}
        onCancel={handlerCancel}
        onConfirm={handlerConfirm}
        selectedId={selectedId}
        readOnly={readOnly}
        nsirTitle={nsirTitle}
      />
      <DropzoneModal 
        onClose={() => setOpenFotoInicial(false)} 
        open={openFotoInicial}
        onSave={async fotos => {
          setOpenFotoInicial(false);
          handlerConfirm({
            fotos: await getFotos(fotos),
            codigo: '000',
            fotoInicio: true,
            fotoFim: false,
            analiseRisco: false,
            dataFotoDrone: moment().valueOf(),
          });
        }}
      />
      <DropzoneModal 
        onClose={() => setOpenFotoFinal(false)} 
        open={openFotoFinal}
        onSave={async fotos => {
          setOpenFotoFinal(false);
          handlerConfirm({
            fotos: await getFotos(fotos),
            codigo: '999',
            fotoInicio: false,
            fotoFim: true,
            analiseRisco: false,
            dataFotoDrone: moment().valueOf(),
          });
        }}
      />
      <DropzoneModal 
        onClose={() => setOpenAnaliseRisco(false)} 
        open={openAnaliseRisco}
        onSave={async fotos => {
          setOpenAnaliseRisco(false);
          handlerConfirm({
            fotos: await getFotos(fotos),
            codigo: '-001',
            fotoInicio: false,
            fotoFim: false,
            analiseRisco: true,
            dataFotoDrone: moment().valueOf(),
          });
        }}
      />
      <DialogDefault
        open={openClone}
        handlerCancel={() => { setOpenClone(false); setCloneId(null); }}
        handlerConfirm={() => {
          setOpenClone(true);
          cloneNsmp(cloneId, () => {
            setCloneId(null);
            setOpenClone(false);
          });
        }}
        title="Você tem que deseja clonar o serviço?"
        confirmMessage="Clonar"
      >
        <DialogContentText> Ao clonar o serviço, todas as informações e fotos serão duplicadas. O novo serviço terá um novo código. </DialogContentText>
      </DialogDefault>
      <DialogDefault
        open={openDeleteClone}
        handlerCancel={() => { setOpenDeleteClone(false); setCloneId(null); }}
        handlerConfirm={() => {
          setOpenDeleteClone(true);
          deleteCloneNsmp(cloneId, () => {
            setOpenDeleteClone(false);
            setCloneId(null);
          });
        }}
        title="Você tem que deseja remover o serviço?"
        confirmMessage="Remover"
      >
        <DialogContentText> A informação será removida e não poderá ser mais recuperado. </DialogContentText>
      </DialogDefault>
    </>
  );
};

ServicesTab.propTypes = {
  nsmps: PropTypes.array.isRequired,
  nsirId: PropTypes.string.isRequired,
  saveNsmp: PropTypes.func.isRequired,
  updateNsmp: PropTypes.func.isRequired,
  authData: PropTypes.object.isRequired,
  userRole: PropTypes.object.isRequired,
  deleteNsmp: PropTypes.func.isRequired,
  cleanSelectedNsmp: PropTypes.func.isRequired,
  nsirStatus: PropTypes.string,
  userId: PropTypes.string.isRequired,
  inspetorId: PropTypes.string.isRequired,
  digitadorId: PropTypes.string.isRequired,
  nsirTitle: PropTypes.string.isRequired,
};

ServicesTab.defaultProps = {
  nsirStatus: ''
};

const mapStateToProps = state => ({
  userRole: selectorsAuth.getRole(state),
  userId: selectorsAuth.getUserId(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actionsNsmp
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesTab);
