import BaseService from './BaseService';
import * as axios from 'axios';
import imageCompression from 'browser-image-compression';

export default class FileService {
  static async uploadFotos(fotos, nsmpId) {
    const promises = [];
    fotos.forEach(foto => promises.push(BaseService.post({ url: 'fotos', data: { ...foto, nsmpId }})));
    return await Promise.all(promises);
  }

  static async importFotosByNsmps(fotos) {
    const promises = [];
    fotos.forEach(foto => promises.push(BaseService.post({ url: 'fotos/mobile', data: foto})));
    return await Promise.all(promises);
  }

  static async updateFile(url, data) {
    return await axios({
      url,
      method: 'PUT',
      data
    });
  }

  static async getFile(url) {
    return await axios.get(url, { responseType: 'blob' });
  }

  static getBase64 = (file) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => resolve("");
    });
  }

  static base64ToFile = (base64, filename) => {
    if (!base64.includes('data:image/png;base64')) {
      base64 = `data:image/png;base64,${base64}`;
    }

    let arr = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
        
    return new File([u8arr], filename, {type:mime});
  }

  static getArquivosToUpload = (arquivos, useExternald = false) => {
    if (arquivos && arquivos.length > 0) {
  
      const arquivosToUpload = [];
      for (let arquivo of arquivos) {
        arquivosToUpload.push({
          id: arquivo.id,
          nome: arquivo.nome,
          extensao: arquivo.extensao,
          status: 'NOT_SYNC',
          externalId: useExternald ? arquivo.externalId : arquivo.id
        });
      }
  
      return arquivosToUpload;
    }
  
    return [];
  }
  
  static uploadArquivos = async (arquivos, arquivosToUpload, field = "id") => {
    let valid = true;

    if (arquivosToUpload && arquivosToUpload.length > 0) {
  
      const validateArquivoDTO = [];
  
      for (let arquivoToUpload of arquivosToUpload) {
        const arquivoList = arquivos.filter(f => f[field] === arquivoToUpload.externalId);
        if (arquivoList && arquivoList.length > 0) {
          try {
            await axios({ url: arquivoToUpload.url, method: 'PUT', data: arquivoList[0].file});
            validateArquivoDTO.push({id: arquivoToUpload.id, valid: true });
          } catch(error) {
            console.error(error);
            validateArquivoDTO.push({id: arquivoToUpload.id, valid: false });
            valid = false;
          }
  
        }
      }
  
      await BaseService.post({ url: 'arquivos/validate', data: {arquivos: validateArquivoDTO} });
    }

    return valid;
  }

  static downloadArquivos = async (arquivos, useName = true) => {
    const downloadArquivos = [];
    
    const arquivosPromisses = [];

    for (let arquivosToDownload of arquivos) {
      arquivosPromisses.push(axios.get(arquivosToDownload.url, { 
        responseType: 'blob',
      }));
    }

    await Promise.allSettled(arquivosPromisses).then(async results => {
      console.log(`Inicia pós download: ${new Date()}`)
      let count = 1;
      for (let result of results) {
        try {
          const arquivoData = arquivos[count - 1];
          const responseFile = result.value;
          let altValue = useName ? arquivoData.nome : `Arquivo ${count}`;

          if (arquivoData.additions) {
            try {
              const additions = JSON.parse(arquivoData.additions)
              for (const [key, value] of Object.entries(additions)) {
                altValue = `${altValue}; ${key}: ${value}` 
              }
            } catch(ea) {
              console.error(ea);
            }
          }

          downloadArquivos.push({
            id: arquivoData.id,
            nome: useName ? arquivoData.nome : `Arquivo ${count}`,
            extensao: arquivoData.extensao,
            alt: altValue,
            file: await this.compress(responseFile.data),
            externalId: arquivoData.externalId,
            downloadUrl: arquivoData.url,
          });
        } catch (e) {
          console.error(e);
        }
        count++;
      }
      console.log(`Finaliza pós download: ${new Date()}`)
    });

    return downloadArquivos;
  }

  static compress = async file => {
    const size = file.size / 1024 / 1024;

    if (size > 3) {
      try {
        const compressedFile = await imageCompression(new Blob([file], {type: 'image/png'}), {
          maxSizeMB: 3,
          maxWidthOrHeight: 1920,
        });
        console.log(`Arquivo comprimido de ${size} MB para ${compressedFile.size / 1024 / 1024} MB`);

        file = compressedFile;
      } catch (error) {
        console.log(error);
      }
    }

    return file;
  }

}